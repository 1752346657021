<template>
  <SelectPuestoMulti
    v-if="tipo == 'SelectPuestoMulti'"
    v-model="_value"
    :add="add"
    :dense="dense"
    :outlined="outlined"
    :clearable="clearable"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    :icon="icon"
    :filtro="{...filtros, id_usuario: store.state.user.id , id_reporte: id_reporte}"
  ></SelectPuestoMulti>
  <SelectPuesto
    v-else-if="tipo == 'SelectPuesto'"
    v-model="_value"
    :add="add"
    :dense="dense"
    :outlined="outlined"
    :clearable="clearable"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    :icon="icon"
   :filtro="{...filtros, id_usuario: store.state.user.id , id_reporte: id_reporte}"
  ></SelectPuesto>
  <DosFechasVue
    v-else-if="tipo == 'SelectDosFechas'"
    v-model="_value"
    :add="add"
    :dense="dense"
    :outlined="outlined"
    :clearable="clearable"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    :icon="icon"
    :filtro="filtros"
  ></DosFechasVue>
  <SelectAlmacen
    v-else-if="tipo == 'SelectAlmacen'"
    v-model="_value"
    :add="add"
    :dense="dense"
    :outlined="outlined"
    :clearable="clearable"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    :icon="icon"
    :filtro="filtros"
  />
  <SelectEstado
    v-else-if="tipo == 'SelectEstado'"
    v-model="_value"
    :add="add"
    :dense="dense"
    :outlined="outlined"
    :clearable="clearable"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    :icon="icon"
    :filtros="filtros"
  />
  <SelectMotivo
    v-else-if="tipo == 'SelectMotivo'"
    v-model="_value"
    :add="add"
    :dense="dense"
    :outlined="outlined"
    :clearable="clearable"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    :icon="icon"
    :filtros="filtros"
  />
  <BuscarProducto
    v-else-if="tipo == 'SelectProducto'"
    v-model="_value"
    :add="add"
    :dense="dense"
    :outlined="outlined"
    :clearable="clearable"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    :icon="icon"
    :filtros="filtros"
  />
  <SelectGrupoNegocioValor
    v-else-if="tipo == 'SelectGrupoNegocio'"
    v-model="_value"
    :add="add"
    :dense="dense"
    :outlined="outlined"
    :clearable="clearable"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    :icon="icon"
    :filtros="filtros"
  />
  <DatePicher
    v-else-if="tipo == 'SelectFecha'"
    v-model="_value"
    :add="add"
    :dense="dense"
    :outlined="outlined"
    :clearable="clearable"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    :icon="icon"
    :filtros="filtros"
  />
  <SelectCategoria
    v-else-if="tipo == 'SelectCategoria'"
    v-model="_value"
    :add="add"
    :dense="dense"
    :outlined="outlined"
    :clearable="clearable"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    :icon="icon"
    :filtros="filtros"
  />
  <SelectSubCategoria
    v-else-if="tipo == 'SelectSubCategoria'"
    v-model="_value"
    :add="add"
    :dense="dense"
    :outlined="outlined"
    :clearable="clearable"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    :icon="icon"
    :filtros="filtros"
  />
  <BuscarEmpleadoId
    v-else-if="tipo == 'SelectEmpleado'"
    v-model="_value"
    :add="add"
    :dense="dense"
    :outlined="outlined"
    :clearable="clearable"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    :icon="icon"
    :filtros="filtros"
  />

  <BuscarCaja
    v-else-if="tipo == 'SelectCaja'"
    v-model="_value"
    :add="add"
    :dense="dense"
    :outlined="outlined"
    :clearable="clearable"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    :icon="icon"
    :filtros="filtros"
  />
  <BuscarProveedorValue
    v-else-if="tipo == 'SelectProveedor'"
    v-model="_value"
    :add="add"
    :dense="dense"
    :outlined="outlined"
    :clearable="clearable"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    :icon="icon"
    :filtros="filtros"
  />
  

  <v-text-field
    v-else-if="tipo == 'Text'"
    v-model="_value"
    :add="add"
    :dense="dense"
    :outlined="outlined"
    :clearable="clearable"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    :prepend-icon="icon ? icons.mdiAccountTie : null"
  />

  <v-autocomplete
    v-else-if="tipo == 'Select'"
    v-model="_value"
    :items="items"
    :loading="loading"
    :clearable="dense"
    hide-details="auto"
    hide-selected
    item-text="text"
    item-value="value"
    :label="label"
    :outlined="outlined"
    :dense="dense"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title> No hay datos </v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:selection="{ item }">
      <span>{{ item.text }} </span>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>
          {{ item.text }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
  <div v-else>sin filtros</div>
</template>

<script>
import SelectPuestoMulti from '@/views/sistema/administracion/archivo/puesto/components/SelectPuestoMulti.vue'
import SelectPuesto from '@/views/sistema/administracion/archivo/puesto/components/SelectPuesto2.vue'
import SelectAlmacen from '@/views/sistema/inventario/maestro/almacen/componentes/Select.vue'
import DosFechasVue from '@/components/DosFechasValue.vue'
import SelectEstado from '@/views/sistema/administracion/archivo/estado/componentes/Select.vue'
import SelectMotivo from '@/views/sistema/puesto/inventario/ajuste/componentes/SelectMotivo.vue'
import BuscarProducto from '@/views/sistema/inventario/producto/componentes/BuscarProductoValue.vue'
import SelectGrupoNegocioValor from '@/components/SelectGrupoNegocioValor.vue'
import DatePicher from '@/components/DatePicker.vue'
import SelectCategoria from '@/views/sistema/inventario/maestro/categoria/componentes/Select.vue'
import SelectSubCategoria from '@/views/sistema/inventario/maestro/sub-categoria/componentes/Select.vue'
import BuscarEmpleadoId from '../../empleado/components/BuscarEmpleadoId.vue'
import BuscarCaja from '../../../ventas/caja/componentes/BuscarCaja.vue'
import BuscarProveedorValue from '@/views/sistema/puesto/compra/proveedor/componentes/BuscarProveedorValue.vue'
import { ref, watch, onBeforeMount } from '@vue/composition-api'
import store from '@/store'
export default {
  components: {
    SelectPuestoMulti,
    SelectAlmacen,
    SelectPuesto,
    DosFechasVue,
    SelectEstado,
    SelectMotivo,
    BuscarProducto,
    SelectGrupoNegocioValor,
    DatePicher,
    SelectCategoria,
    SelectSubCategoria,
    BuscarEmpleadoId,
    BuscarCaja,
    BuscarProveedorValue
  },
  props: {
    value: null,
    id_reporte:null,
    tipo: String,
    disabled: Boolean,
    dense: Boolean,
    outlined: Boolean,
    icon: Boolean,
    clearable: Boolean,
    loading: Boolean,
    rules: Array,
    add: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Locatario/Area',
    },
    filtros: Object,
    items: Array,
  },

  setup(props, contex) {
    const _value = ref(null)
    onBeforeMount(() => {
      _value.value = props.value
    })

    watch(props, () => {
      _value.value = props.value
    })

    watch(_value, () => {
      contex.emit('input', _value.value)
      contex.emit('change', _value.value)
    })

    return { _value ,store}
  },
}
</script>

<style>
</style>