<template>
  <div>
    <v-text-field
      hide-details
      v-model="dates"
      :label="label"
      :prepend-icon="icon ? icons.mdiCalendar : null"
      :readonly="readonly"
      @click="modal = true"
      @click:clear="clear()"
      :clearable="clearable"
      :dense="dense"
      :outlined="outlined"
    >
    </v-text-field>

    <v-dialog ref="dialogoref" v-model="modal" :return-value.sync="dates" persistent width="350">
      <v-date-picker :allowed-dates="disabledAfterToday" v-model="dates" range locale="es-ve" :events="functionEvents">
        <v-card-text class="mt-3">
          <v-row>
            <v-col cols="12" class="my-0 mx-0 py-0 px-0">
              <small v-if="!funciones.fechasHorarioOficina()">
                Esta fuera del horario de oficina no podra visualizar historial antes del:
                {{ funciones.configuracionSelect(13) }}</small
              >
            </v-col>
            <v-col class="text-center pt-0 pl-0 pr-0 pb-4">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="secondary" dark v-bind="attrs" v-on="on" block x-small> Rangos </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(item, index) in rangos" :key="index" link @click="seleccionarRango(item)">
                    <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center pt-0 pl-0 pr-0 pb-0">
              <v-btn text color="primary" @click="modal = false"> Cancelar </v-btn>
              <v-btn text color="primary" @click="Seleccionar()"> Aceptar </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-date-picker>
    </v-dialog>
  </div>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiCalendar } from '@mdi/js'
import moment from 'moment'
import MaestroService from '@/api/servicios/MaestroService'
import store from '@/store'
import funciones from '@/funciones/funciones'
export default {
  props: {
    value:Array ,
    icon: Boolean, 
    label: {
      type: String,
      default: 'Fechas',
    },
    disabled: Boolean,
    dense: Boolean,
    outlined: Boolean,
    icon: Boolean,
    clearable: Boolean,
    rules: Array,
    add: {
      type: Boolean,
      default: false,
    }, 
    filtros: Object,
  },
  setup(props, contex) {
    const dates = ref([moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
    const modal = ref(false)
    const dialogoref = ref(null)
    const toggle_exclusive = ref()
    const rangoSelect = ref(1)
    const diasCerrados = ref([])
    const diasMovimietos = ref([])
    const diasMovimietosDinero = ref([])
    const disabledAfterToday = date => {
      const today = moment()
      if (!funciones.fechasHorarioOficina()) {
        return moment(date) < today && moment(date) >= moment(funciones.configuracionSelect(13))
      }
      return moment(date) < today
    }

    const comprobarFechasHistorial = fechas => {
      let valor = false
      if (
        moment(fechas.desde) < moment(funciones.configuracionSelect(13)) &&
        moment(fechas.hasta) >= moment(funciones.configuracionSelect(13))
      ) {
        valor = true
      }

      return valor
    }

    const rangos = ref([
      { id: 1, nombre: 'Hoy', fechas: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')] },
      {
        id: 2,
        nombre: 'Ayer',
        fechas: [moment().subtract(1, 'days').format('YYYY-MM-DD'), moment().subtract(1, 'days').format('YYYY-MM-DD')],
      },
      {
        id: 3,
        nombre: 'Esta Semana',
        fechas: [
          moment().startOf('week').subtract(-1, 'days').format('YYYY-MM-DD'),
          moment().endOf('week').subtract(-1, 'days').format('YYYY-MM-DD'),
        ],
      },
      {
        id: 4,
        nombre: 'Semana Pasada',
        fechas: [
          moment().subtract(1, 'week').startOf('week').subtract(-1, 'days').format('YYYY-MM-DD'),
          moment().subtract(1, 'week').endOf('week').subtract(-1, 'days').format('YYYY-MM-DD'),
        ],
      },
      {
        id: 5,
        nombre: 'Este Mes',
        fechas: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
      },
      {
        id: 6,
        nombre: 'Mes Pasado',
        fechas: [
          moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
          moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
        ],
      },
      {
        id: 7,
        nombre: 'Este año',
        fechas: [moment().startOf('year').format('YYYY-MM-DD'), moment().endOf('year').format('YYYY-MM-DD')],
      },
      {
        id: 8,
        nombre: 'Año pasado',
        fechas: [
          moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
          moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
        ],
      },
    ])
    const functionEvents = date => {
      let array = []
      if (diasCerrados.value.includes(moment(date).format('YYYY-MM-DD'))) {
        array.push('warning')
      } else {
        if (diasMovimietosDinero.value.includes(moment(date).format('YYYY-MM-DD'))) {
          array.push('error')
        }
      }

      if (diasMovimietos.value.includes(moment(date).format('YYYY-MM-DD'))) {
        array.push('info')
      }

      return array
    }
    const seleccionarRango = item => {
      dates.value = item.fechas
    }
    onBeforeMount(() => {
      seleccionarRango({ fechas:( props.value ?  [props.value.desde, props.value.hasta] : null)})
    })
    watch(props, () => {
      seleccionarRango({ fechas: ( props.value ?  [props.value.desde, props.value.hasta] : null) })
    })

    const ArrayDiasCerrados = () => {
      MaestroService.ArrayDiasCerrados()
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            diasCerrados.value = response.data.datos.arreglo.split(',')
            diasMovimietos.value = response.data.datos.arreglo_movimientos.split(',')
            diasMovimietosDinero.value = response.data.datos.arreglo_movimientos_dinero.split(',')
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'warning',
            })
          }
        })
        .catch(err => {
          console.error(err)
          store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'ArrayDiasCerrados',
          })
        })
    }

    onBeforeMount(() => {
      ArrayDiasCerrados()
    })

    const Seleccionar = () => {
      let array = []

      if (dates.value[1] != undefined) {
        const fecha1 = new Date(dates.value[0])
        const fecha2 = new Date(dates.value[1])
        if (fecha1 > fecha2) {
          array = [dates.value[1], dates.value[0]]
        } else {
          array = dates.value
        }
      } else {
        array = dates.value
      }
      const fechasReturn = {
        desde: array[0],
        hasta: array[1] != undefined ? array[1] : array[0],
      }

      if (!comprobarFechasHistorial(fechasReturn)) {
        dialogoref.value.save(array)
        contex.emit('input', fechasReturn)
      } else {
        store.commit('setAlert', {
              message: "No se pueden seleccionar 2 fechas entre el historial y el actual",
              type: 'warning',
            })
  
      }
    }

    const clear = () => {
      dates.value = null
      contex.emit('input', {
        desde: '',
        hasta: '',
      })
    }

    const valorText = () => {
      return dates.value.join(' ~ ')
    }

    return {
      icons: {
        mdiCalendar,
      },
      modal,
      dates,
      Seleccionar,
      valorText,
      dialogoref,
      clear,
      toggle_exclusive,
      rangos,
      rangoSelect,
      diasCerrados,
      seleccionarRango,
      functionEvents,
      disabledAfterToday,
      funciones,
    }
  },
}
</script>