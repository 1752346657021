<template>
  <div>
    <v-autocomplete
      :prepend-icon="icon ? icons.mdiAccountTie : null"
      v-model="model"
      :items="items"
      :filter="customFilter"
      :loading="isLoading"
      :search-input.sync="search"
      :clearable="dense"
      hide-details="auto"
      hide-selected
      item-text="nombre"
      item-value="id"
      :label="label"
      :outlined="outlined"
      :dense="dense"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title> No hay empleados </v-list-item-title>
        </v-list-item>
      </template>
      <template v-slot:selection="{ item }">
        <span>{{ item.nombre }}  </span>
      </template>
      <template v-slot:item="{ item }">
       
        <v-list-item-content>
          <v-list-item-title>
            {{ item.nombre }}  
            <br />
            <small> {{ item.ci }}</small>
          </v-list-item-title>
        </v-list-item-content>
       
      </template>
    </v-autocomplete> 
  </div>
</template>
  <script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAccountTie } from '@mdi/js'
import MaestroService from '@/api/servicios/MaestroService'
export default {
  props: {
    value: Number,
    icon: Boolean,
    label: {
      type: String,
      default: 'Buscar Caja',
    },
    disabled: Boolean,
    dense: Boolean,
    outlined: Boolean, 
    clearable: Boolean,
    rules: Array,
    add: {
      type: Boolean,
      default: false,
    },
     
    filtros: Object,
  },

  setup(props, context) {
    const dialog = ref(false)
    const isLoading = ref(false)
    const items = ref([])
    const model = ref(null)
    const search = ref(null)
    const tab = ref(null)

    const customFilter = (item, queryText, itemText) => {
      const filtro1 = item.id.toString()
      const filtro2 = item.nombre
      const searchText = queryText.toUpperCase()

      return filtro1?.indexOf(searchText) > -1 || filtro2?.indexOf(searchText) > -1
    }

    watch(model, () => {
      context.emit('input', model.value)
    })
    onBeforeMount(() => {
      Buscar()
    })

    /*
      watch(search, () => {
        Buscar()
      })*/

    const Buscar = () => {
      // Items have already been loaded
      /*if (items.value.length > 0)
                  return*/

      if (isLoading.value == true) return

      isLoading.value = true

      // Lazily load input items

      MaestroService.CajasListar({ busqueda: search.value })
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            items.value = response.data.datos
          } else {
            items.value = []
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => (isLoading.value = false))
    }

    return {
      icons: {
        mdiAccountTie,
      },
      dialog,
      isLoading,
      items,
      model,
      search,
      tab,
      customFilter,
    }
  },
}
</script>