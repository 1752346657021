<template>
  <div v-if="!cargando">
    <p class="mb-6">{{ datos.nombre }}</p>

    <v-card>
      <v-card-subtitle>Filtros</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="6" v-for="(item, index) in datos.filtros" :key="index">
            <Filtros
              :tipo="item.tipo"
              :add="item.add"
              :clearable="item.clearable"
              :dense="item.dense"
              :disabled="item.disabled"
              :filtros="item.filtrosValue"
              :icon="item.icon"
              :label="item.label"
              :outlined="item.outlined"
              :rules="JSON.parse(item.rules)"
              v-model="item.value"
              @change="reemplazarFiltros"
              :items="item.items ? JSON.parse(item.items) : []"
            />
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn small class="secondary mr-2" @click="limpiar()">Limpiar</v-btn>
            <v-btn small class="primary" @click="buscar()">Buscar</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-subtitle>Resultado</v-card-subtitle>
      <v-card-text>
        <TablaSimpleReporte
          :nombreReporte="datos.nombre"
          :cabeceraIn="datos.cabeceras"
          :datosIn="datosBuscqueda"
          :exportar="['EXCEL', 'PDF']"
          :orientacionReporte="'l'"
          :fontSize="4"
          :height="600"
        ></TablaSimpleReporte>
      </v-card-text>
    </v-card>
  </div>
  <div v-else class="text-center">Cargando datos del reporte</div>
</template>
  
  <script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import TablaSimpleReporte from '@/components/TablaSimpleReporte.vue'
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import Filtros from './componentes/Filtros.vue'
import MaestroService from '@/api/servicios/MaestroService'
import router from '@/router'
// demos

export default {
  props: {},
  components: {
    TablaSimpleReporte,
    Filtros,
  },
  mounted() {
    if (this.$route.params.id) {
      if (this.$route.params.id > 0) {
        this.cargar(this.$route.params.id)
      }
    }else{
      router.push('/')
    }
  },

  setup(props) {
    const filtro_init = ref('')
    const filtros = ref([])
    const datos = ref({})
    const cargando = ref(false)
    const datosBuscqueda = ref([])
    const cargar = id => {
      cargando.value = true
      MaestroService.reportesView({ id })
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            datos.value = JSON.parse(JSON.stringify(response.data.datos))
            datos.value.filtros = JSON.parse(datos.value.filtros)
            datos.value.cabeceras = JSON.parse(datos.value.cabeceras)

            filtro_init.value = response.data.datos.filtros
            reemplazarFiltros()
          }else if (response.data.mensaje == 'NO TIENE PERMISO PARA ACCEDER') {
          
          router.push('/')
        }
        })
        .catch(error => {
          console.log('error', error)
        })
        .finally(() => {
          cargando.value = false
        })
    }

    onBeforeMount(() => {
      reemplazarFiltros()
    })

    const obtenerValores = str => {
      const regex = /\$\!(.*?)\!\$/g
      let matches = []
      let match
      while ((match = regex.exec(str)) !== null) {
        matches.push(match[1])
      }
      return matches
    }

    const reemplazarValores = (str, valores) => {
      return str.replace(/\$\!(.*?)\!\$/g, () => `${valores.shift()}`)
    }

    const reemplazarFiltros = () => {
      console.log('reemplazarFiltros')
      datos.value.filtros.forEach(element => {
        if (obtenerValores(element.filtros).length > 0) {
          console.log(element.filtros)
          const valores = []
          obtenerValores(element.filtros).forEach(val => {
            console.log(filtros.value.find(it => it.id == val))
            if (filtros.value.find(it => it.id == val)) {
              if (Array.isArray(filtros.value.find(it => it.id == val)?.value)) {
                if (filtros.value.find(it => it.id == val).value[0] != undefined) {
                  valores.push(filtros.value.find(it => it.id == val).value[0].toString())
                }
              } else {
                if (filtros.value.find(it => it.id == val)?.value != undefined) {
                  valores.push(filtros.value.find(it => it.id == val)?.value.toString())
                }
              }
            }
          })

          var newvalor = reemplazarValores(element.filtros, valores)
          if (newvalor != null && newvalor != undefined) {
            console.log('reemplazarFiltros', newvalor)
            element.filtrosValue = JSON.parse(newvalor)
          }
        } else {
          if (element.filtros != '') {
            element.filtrosValue = JSON.parse(element.filtros)
          }
        }
      })
    }
    const limpiar = () => {
      console.log(filtro_init.value)
      datos.value.filtros = JSON.parse(filtro_init.value)
      datosBuscqueda.value = []
    }

    const buscar = () => {
      //alert("buscar")
      var _filtros = ' '

      datos.value.filtros.forEach(elm => {
        _filtros += `"$!${elm.id}!$":"${elm.value ? elm.value : elm.default}",`
      })

      _filtros = '{' + _filtros.substring(0, _filtros.length - 1) + '}'

      console.log(_filtros)

      const parametros = {
        id: datos.value.id,
        filtros: JSON.parse(_filtros),
      }

      MaestroService.ejecutarReporte(parametros)
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            datosBuscqueda.value = response.data.datos
          } 
        })
        .catch(error => {})
        .finally(() => {})
    }

    return {
      filtros,
      reemplazarFiltros,
      cargando,
      cargar,
      datos,
      datosBuscqueda,
      limpiar,
      buscar,
    }
  },
}
</script>
  