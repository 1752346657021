<template>
  <div>
    <v-autocomplete
      :prepend-icon="icon ? icons.mdiAccount : null"
      v-model="model"
      :items="items"
      :filter="customFilter"
      :loading="isLoading"
      :search-input.sync="search"
      :clearable="clearable"
      hide-details="auto"
      hide-selected
      item-text="nombre"
      item-value="id"
      :label="label"
      :outlined="outlined"
      :dense="dense"
      :rules="rules"
      :append-outer-icon="add ? icons.mdiPlusCircleOutline : null"
      @click:append-outer="abrirModal()"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title> No hay proveedor </v-list-item-title>
        </v-list-item>
      </template>
      <template v-slot:selection="{ item }">
        <v-list-item-content>
          <v-list-item-title>
            <small>
              <strong>{{ item.nombre }}</strong></small
            >
            <br />
            <small> {{ item.documento }}</small>
          </v-list-item-title>
        </v-list-item-content>
      </template>
      <template v-slot:item="{ item }">
        <v-list-item-avatar color="primary" size="30" class="white--text">
          <small>{{ item.id }}</small>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            <strong>{{ item.nombre }}</strong>
            <br />
            <small> {{ item.descripcion }}</small>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action-text>
          <small>{{ item.documento }}</small>
        </v-list-item-action-text>
      </template>
    </v-autocomplete>

    <v-dialog v-model="dialog" width="700">
      <Actualizar @GetActualizarLista="GetActualizarLista"></Actualizar>
    </v-dialog>
  </div>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAccount, mdiPlusCircleOutline } from '@mdi/js'
import ProveedorServices from '@/api/servicios/inventario/maestro/ProveedorServices'
import Actualizar from './Actualizar.vue'
import config from '@/api/config'
export default {
  props: {
    value: null,
    icon: Boolean,
    rules: Array,
    label: {
      type: String,
      default: ' Buscar Proveedor ',
    },
    filtros: Object,
    add: {
      type: Boolean,
      default: true,
    },
    disabled: Boolean,
    dense: Boolean,
    outlined: Boolean ,
    clearable: Boolean 
  },
  components: {
    Actualizar,
  },
  watch: {
    value: function (_new, _old) {
      if ((_new) != (_old) && _new != this.model) {
       // alert("aqui")
        this.asignarProveedor(_new)
      }
    }, 
  },
  setup(props, context) {
    const dialog = ref(false)
    const isLoading = ref(false)
    const items = ref([])
    const model = ref(null)
    const search = ref(null)
    const tab = ref(null)
    const customFilter = (item, queryText, itemText) => {
      const filtro1 = item.documento.toUpperCase()
      const filtro2 = item.nombre.toUpperCase()
      const filtro3 = item.descripcion.toUpperCase()
      const filtro4 = item.id.toString().toUpperCase()
      const searchText = queryText.toUpperCase()

      return (
        filtro1?.indexOf(searchText) > -1 ||
        filtro2?.indexOf(searchText) > -1 ||
        filtro3?.indexOf(searchText) > -1 ||
        filtro4?.indexOf(searchText) > -1
      )
    }

    onBeforeMount(()=>{
      if (props.value) {
        asignarProveedor(props.value)
      }
    })
    const GetActualizarLista = item => {
      console.log('GetActualizarLista', item)
      items.value.push(item)
      model.value = item.id
      dialog.value = false
    }
    watch(model, () => {
      context.emit('input', model.value)
    })
    watch(search, () => {
      Buscar()
    })
    const limpiar = () => {
      model.value = null
    }
    var asignarProveedorId = null
    var asignarProveedorNombre = null

    const asignarProveedor = async item => {
      asignarProveedorNombre = item
      asignarProveedorId = item

      Buscar()
    }
    const abrirModal = () => {
      dialog.value = true
    }
    const Buscar = () => {
      // Items have already been loaded
      /*if (items.value.length > 0)
                return*/

      if (isLoading.value == true) return

      isLoading.value = true
      setTimeout(() => {
        // Lazily load input items
        var filtro = { ...props.filtros }
        if (search.value) {
          filtro = { ...filtro, valor: search.value }
        }
        if (asignarProveedorId) {
          filtro = { ...filtro, valor: asignarProveedorNombre }
        }

        ProveedorServices.proveedorListarBusqueda({ ...filtro })
          .then(response => {
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              items.value = response.data.datos

              if (asignarProveedorId) {
                model.value = asignarProveedorId
                asignarProveedorId = null
                asignarProveedorNombre = null
              }
            } else {
              items.value = []
            }
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (isLoading.value = false))
      }, 1000)
    }

    return {
      icons: {
        mdiAccount,
        mdiPlusCircleOutline,
      },
      dialog,
      isLoading,
      items,
      model,
      search,
      tab,
      customFilter,
      config,
      limpiar,
      asignarProveedor,
      abrirModal,
      GetActualizarLista,
    }
  },
}
</script>